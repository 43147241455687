import React from "react";
import Header from "./Header";
import Content from "./Content";
import Hero from "./Hero";
import Footer from "./Footer";

function Body() {
  return (
    <div>
      <Header />
      <Hero
        heading={
          "Unlock India's Digital Rupee Era with EasePay's CBDC Experience"
        }
        desc={`Your Revolutionary central bank digital currency app designed to simplify digital transactions and enhance financial accessibility.`}
        btn1={"Join Waiting List"}
        btn2={"Learn More"}
      />
      <Content
        heading={"Key Features of Ease Pay"}
        heading1={`Discover the powerful features that make EasePay the ultimate choice for digital payments.`}
      />
      {/* <TrustedBy /> */}
      <Footer />
    </div>
  );
}

export default Body;
