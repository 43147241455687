export default function DashboardHero() {
  return (
    <div className="py-4 w-auto">
      <div className="gap-y-10 xl:grid-cols-3">
        <div className="flex items-center gap-x-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#424874"
            width="50px"
            height="50px"
            viewBox="0 0 0.48 0.48"
          >
            <path d="M0.24 0.02 0.06 0.1v0.12c0 0.111 0.077 0.215 0.18 0.24 0.103 -0.025 0.18 -0.129 0.18 -0.24V0.1zm0 0.22h0.14c-0.011 0.082 -0.066 0.156 -0.14 0.179V0.24H0.1V0.126l0.14 -0.062z" />
          </svg>
          <div>
            <h3 className="text-base font-semibold leading-7 text-dark4">
              198.54.119.200
            </h3>
            <div className="flex gap-4">
              <p className="text-sm font-semibold leading-6 text-indigo-600">
                Checked every 30 second
              </p>
              <p className="bg-gray-50">|</p>
              <p className="text-sm font-semibold leading-6 text-green-600">
                Vulnerability report
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
