import React, { useState } from "react";
import Button from "./Button";
function capitalizeFirstLetter(str) {
  if (!str) {
    str = "NA";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Table() {
  const walletUserList = {
    data: [
      {
        number: "#02",
        name: "Kenneth  Koch",
        assignedTo: "Jone Doe",
        status: "Closed",
        severity: "Low",
        cvssScore: "7/10",
      },
      {
        number: "#03",
        name: "Kenneth  Koch",
        assignedTo: "Jone Doe",
        status: "Medium",
        severity: "Active",
        cvssScore: "7/10",
      },
      {
        number: "#01",
        name: "Kenneth  Koch",
        assignedTo: "Jone Doe",
        status: "Closed",
        severity: "High",
        cvssScore: "7/10",
      },
    ],
    columns: [
      {
        key: "number",
        title: "No.",
        dataType: "string",
      },
      {
        key: "name",
        title: "Name",
        dataType: "string",
      },
      {
        key: "assignedTo",
        title: "Assigned To",
        dataType: "string",
      },

      {
        key: "status",
        title: "Status",
        dataType: "string",
      },
      {
        key: "severity",
        title: "Severity",
        dataType: "string",
      },
      {
        key: "cvssScore",
        title: "CVSS Score",
        dataType: "string",
      },
    ],
  };

  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const handleRowClick = (user) => {
    // setSelectedUser(user);
    // setIsSlideOpen(true);
  };

  return (
    <div className="font-custom">
      <div className="py-12">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
          <table className="w-full text-left rtl:text-right  ">
            <thead className="text-small text-gray-700 font-extrabold">
              <tr>
                {walletUserList?.columns.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 font-medium font-sans "
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="hover:cursor-pointer text-sm border border-gray-200">
              {walletUserList?.data.map((user, userIndex) => (
                <tr
                  key={userIndex}
                  className={`hover:bg-background  text-black border-b border-gray-200"  ${
                    isSlideOpen && "pointer-events-none"
                  }`}
                  onClick={() => handleRowClick(user)}
                >
                  {walletUserList?.columns.map((column, columnIndex) => (
                    <td key={columnIndex} className="px-6 py-4 ">
                      {column.key === "status" ? (
                        <div className="mt-1 flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-emerald-800/15 p-1">
                            <div className="h-1 w-1 ps-1 rounded-full bg-emerald-500 " />
                          </div>
                          {capitalizeFirstLetter(
                            user[column.key]?.toLowerCase()
                          )}
                        </div>
                      ) : (
                        <div>
                          {capitalizeFirstLetter(
                            user[column.key]?.toLowerCase()
                          )}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Table;
