import React from "react";
import Hero from "./../Hero";
import NavBar from "./Navbar";
import Footer from "./../Footer";
import TrustedBy from "./../TrustedBy";
import Price from "./../Price";
import Vulnerability from "./Vulnerability";
function Security() {
  return (
    <div className="bg-white">
      <NavBar />
      <Hero
        heading={"Deploy securely with EaseSecure – trusted by businesses"}
        desc={
          "Discover unparalleled ease and security with EasePay – revolutionizing payments for businesses prioritizing seamless transactions and robust protection."
        }
        btn1={"Schedule a demo call"}
        btn2={"Get started in 5 minutes"}
      />
      <Vulnerability />
      {/* <TrustedBy /> */}

      <Footer />
    </div>
  );
}

export default Security;
