import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import MockUp from "../images/mock-up.png";
export default function Content({ heading, heading1, heading2, features }) {
  return (
    <div className="relative px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0 bg-gray-50">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-dark4"></p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-dark4 sm:text-4xl">
                {heading}
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700 text-justify ">
                {heading1}
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            // src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            src={MockUp}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4 text-justify ">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                {heading2 && <b>EasePay's</b>} {heading2}
              </p>
              <ul role="list" className=" space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-dark4"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {features
                        ? features[0]?.heading
                        : "Instant and secure transactions"}
                    </strong>{" "}
                    {features ? features[0]?.description : ``}
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon
                    className="mt-1 h-5 w-5 flex-none text-dark4"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {features ? features[1]?.heading : `Low transaction fees`}{" "}
                    </strong>{" "}
                    {features ? features[1]?.description : ``}
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-dark4 text-justify "
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {features
                        ? features[2]?.heading
                        : `Seamless integration with existing banking systems`}
                    </strong>{" "}
                    {features ? features[2]?.description : ``}
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-dark4"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {features
                        ? features[0]?.heading
                        : "Intuitive user interface for effortless navigation"}
                    </strong>{" "}
                    {features ? features[0]?.description : ``}
                  </span>
                </li>

                <li className="flex gap-x-3">
                  <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-dark4 text-justify "
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {features
                        ? features[2]?.heading
                        : `Accessible across multiple platforms, ensuring convenience on the go.`}
                    </strong>{" "}
                    {features ? features[2]?.description : ``}
                  </span>
                </li>
              </ul>

              <h2 className="mt-10 text-2xl font-bold tracking-tight text-dark4">
                Why Choose Ease Pay ?
              </h2>
              <p className="mt-6 text-justify ">
                <b className="text-dark4">At Ease Pay</b>, We prioritizes security and adheres to
                stringent compliance standards, ensuring the safety of your
                transactions and personal information. With EasePay, accessing
                and utilizing India's Digital Rupee has never been easier. Our
                intuitive platform combines simplicity with robust security,
                ensuring that every transaction is swift, secure, and
                hassle-free. Whether you're sending money to family, making
                purchases, or managing your finances, EasePay empowers you with
                the tools you need to navigate the digital landscape with
                confidence. Join the revolution today and experience the future
                of finance with EasePay.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
