import React from "react";

function Card() {
  return (
    <div className="w-96 mt-2 bg-white rounded-lg drop-shadow-xl">
      <div className="flow-root rounded-lg border border-gray-100 py-2 shadow-sm">
        <dl className="text-sm">
          <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">ID 4321</dt>
            <dd className="text-gray-700 sm:col-span-2">Mr</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Name</dt>
            <dd className="text-gray-700 sm:col-span-2">John Frusciante</dd>
            <dt className="font-medium text-gray-900">Name</dt>
            <dd className="text-gray-700 sm:col-span-2">John Frusciante</dd>
            <dt className="font-medium text-gray-900">Name</dt>
            <dd className="text-gray-700 sm:col-span-2">John Frusciante</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Card;
