import Footer from "../Footer";
import Header from "../Header";

const posts = [
  {
    id: 1,
    title: "The next big payments disruptors may surprise you",
    href: "https://bankingblog.accenture.com/the-next-big-payments-disruptors-may-surprise-you",
    description:
      "The huge change in customer behaviors and expectations during the pandemic has made this the number one driver of disruption in payments for banks around the world. In our survey, 48% of North American banks and 39% of all banks considered it one of their top two disruption factors. ",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    category: { title: "CBDC", href: "#" },
    author: {
      name: "Accenture Banking",
      role: "Company Blog",
      href: "#",
      imageUrl:
        "https://bankingblog.accenture.com/wp-content/uploads/userphoto/18.jpg",
    },
  },
  {
    id: 2,
    title: "Central Bank Digital Currency Development Enters the Next Phase ",
    href: "https://www.imf.org/en/Blogs/Articles/2023/11/20/central-bank-digital-currency-development-enters-the-next-phase",
    description:
      "Many of the world’s monetary authorities are seeking more guidance on how best to pursue digital forms of central bank money in a fast-changing landscape.bank money in a fast-changing landscape.",
    date: "November 20, 2023",
    datetime: "2020-03-16",
    category: { title: "CBDC", href: "#" },
    author: {
      name: "Tobias Adrian",
      role: "Company Blog",
      href: "#",
      imageUrl:
        "https://www.imf.org/en/Blogs/Articles/2023/11/20/-/media/Images/IMF/Blog/Logo/IMF-Blog-Color-Logo-Mobile.ashx",
    },
  },

];

export default function Blog() {
  return (
    <div>
      <Header />
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Global Talks 
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              See what market leaders are saying
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img
                    src={post.author.imageUrl}
                    alt=""
                    className="h-10 w-10 rounded-full bg-gray-50"
                  />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href={post.author.href}>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
