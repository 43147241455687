import React from "react";

function Button() {
  return (
    <div>
    
      <a
        className="inline-flex items-center gap-1 rounded  bg-dark4 p-2 text-white  "
        href="#"
      >
        <svg
          fill="#FFFFFF"
          width="16px"
          height="16px"
          viewBox="0 0 0.32 0.32"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="1.414"
        >
          <path d="M0.139 0.074A0.014 0.014 0 0 0 0.126 0.06H0.099a0.014 0.014 0 0 0 -0.014 0.014v0.173c0 0.007 0.006 0.014 0.014 0.014h0.027a0.014 0.014 0 0 0 0.014 -0.014V0.074zm0.096 0a0.014 0.014 0 0 0 -0.014 -0.014h-0.027a0.014 0.014 0 0 0 -0.014 0.014v0.173c0 0.008 0.006 0.014 0.014 0.014h0.027c0.007 0 0.014 -0.006 0.014 -0.014z" />
        </svg>

        <span className="text-sm font-medium"> Pause </span>
      </a>
    </div>
  );
}

export default Button;
