import React from "react";
import Detail from "./Detail";
import Payload from "./Payload";
import DashboardHero from "./DashboardHero";
import Card from "./Card";
import Table from './Table'
function Dashboard() {
  return (
    <div className="flex h-full w-full p-4 bg-dark1">
      <div className="w-full">
       <Table/>
        <DashboardHero />
        <div className="px-4">
          <div className="flex gap-4 will-change-auto justify-between ">
            <div>
              <p className="font-bold text-2xl text-gray-900">Service Info</p>
              <Detail />
            </div>
            <div className="ml-10">
              <p className="font-bold text-2xl text-gray-900 ">
                Vulnerability Info
              </p>
              <Payload />
            </div>
            <div className="ml-10">
              <p className="font-bold text-2xl text-gray-900">
                Security Info
              </p>
              <Payload />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col h-full p-10 justify-between">
        <div>
          <Card />
        </div>
        <div>
          <Card />
        </div>
        <div>
          <Card />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
