import React from "react";

function Payload() {
  return (
    <div>
      <div className="flow-root mt-5 p-2 ">
        <dl className="-my-3 divide-y divide-gray-100 text-sm">
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Last testing</dt>
            <dd className="text-gray-700 sm:col-span-2">
              Jun 12, 2023, 15:06:06 [2:22:10]
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Subnet mask</dt>
            <dd className="text-gray-700 sm:col-span-2">254.235.36.2</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Interface</dt>
            <dd className="text-gray-700 sm:col-span-2">FastEthernet0.1</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">DOS Software</dt>
            <dd className="text-gray-700 sm:col-span-2">Attack [14.6]</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Classification</dt>
            <dd className="text-gray-700 sm:col-span-2">
              Botnet, launching, integration
            </dd>
          </div>
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Category</dt>
            <dd className="text-gray-700 sm:col-span-2">
              136, 168, 154, [124]
            </dd>
          </div>
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Average time</dt>
            <dd className="text-gray-700 sm:col-span-2">0.9 Sec</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Payload;
