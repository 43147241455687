import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Body from "./component/Body";
import ProductList from "./component/marketplace/ProductList";
import Security from "./component/security/Security";
import SecurityBody from './component/admin/Body'
import SignIn from "./component/SignIn";
import About from "./component/about";
import Blog from "./component/blog/Blog";
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
  },
  {
    path: "/market-place",
    element: <ProductList />,
    errorElement: <Error />,
  },
  {
    path: "/security",
    element: <Security />,
    errorElement: <Error />,
  },
  {
    path: "/dashboard",
    element: <SecurityBody />,
    errorElement: <Error />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
    errorElement: <Error />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <Error />,
  },
  {
    path: "/blog",
    element: <Blog />,
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={appRouter} />);
