import React from "react";

function Detail() {
  return (
    <div>
      <div className="flow-root mt-5  p-2">
        <dl className="-my-3 divide-y divide-gray-100 text-sm">
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Protection</dt>
            <dd className="text-gray-700 sm:col-span-2">Stopped</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Application</dt>
            <dd className="text-gray-700 sm:col-span-2">Ease Pay Service</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Domain</dt>
            <dd className="text-gray-700 sm:col-span-2">Default domain, extra domain</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Source</dt>
            <dd className="text-gray-700 sm:col-span-2">TCP-IP 2.1.26.48.2:02563</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Category</dt>
            <dd className="text-gray-700 sm:col-span-2">Payment</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Port</dt>
            <dd className="text-gray-700 sm:col-span-2">136, 168, 154, [124]</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Branch</dt>
            <dd className="text-gray-700 sm:col-span-2">development</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Detail;
