import React from "react";
import Navbar from "./Navbar";
import Content from "./../Content";
import Footer from "./../Footer";
import Price from "./../Price";

function Vulnerability() {
  return (
    <div className="bg-dark1">
      <Content
        heading={"Strengthen your security with Ease Secure"}
        heading1={
          "Stay one step ahead with our intelligent vulnerability scanner"
        }
        heading2={
          "vulnerability scanner has been built on years of security intelligence and data. Scan your assets with 8000+ tests and ensure you are covering every loophole."
        }
        features={[
          {
            heading: "5000+ tests & compliance checks",
            description:
              "EaseSecures's comprehensive scanner perform 8000+ tests, which include checking for known CVEs, OWASP Top 10, SANS 25. Our security engine covers all the essential tests required for you to achieve  ISO 27001, HIPAA, SOC2 or GDPR compliance.",
          },
          {
            heading: "Scan logged-in pages",
            description:
              "EaseSecure's scanner also analyzes pages behind the login screen to ensure every possible area of your application is secure. Use our browser extension to record your login seamlessly and don’t miss a single vulnerability.",
          },

          {
            heading: "Scan PWA/SPAs apps",
            description:
              "We scan for progressive web apps (PWA) and Single Page Apps (SPAs) ensuring that you’re 100% secure, no matter what your tech stack is.",
          },
        ]}
      />
      <Price />
    </div>
  );
}

export default Vulnerability;
