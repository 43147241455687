import Header from "./Header";
import Footer from './Footer'
const links = [
  { name: "Governments News ", href: "#" },
  { name: "Other Banking Services", href: "#" },
  { name: "How Ease Pay Can help", href: "#" },
  { name: "Meet our leadership", href: "#" },
];
const stats = [
  { name: "Offices worldwide", value: "12" },
  { name: "Full-time colleagues", value: "300+" },
  { name: "Hours per week", value: "40" },
  { name: "Paid time off", value: "Unlimited" },
];

export default function About() {
  return (
    <div>
      <Header />
      <div className="relative isolate overflow-hidden  py-24 sm:py-32">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#5d599b] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-5xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Central Bank Digital Currency (CBDC)
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              CBDC, or Central Bank Digital Currency, is a legal tender issued
              by the Reserve Bank of India. Also called the “digital rupee” or
              e₹, it will offer features of physical cash like trust, safety and
              settlement finality with atomicity (i.e. immediate settlement of
              transactions) in digital mode. e₹ represents a direct claim on the
              central bank. It can be used to carry out transactions or store
              value digitally, similar to the manner in which currency notes can
              be used in physical form.
            </p>

            <p className="mt-6 text-medium leading-8 text-gray-300">
              Conversion Rates & Denominations The conversion rate will be 1
              Digital Rupee = 1 Rupee and it will have the denominations as the
              physical Rupee i.e. ₹ 1, ₹ 2, ₹ 10, ₹ 20, ₹ 50, ₹ 100 , ₹ 500 and
              even 50 paise coins The e₹ will be held in an e₹ wallet issued by
              banks like IDFC FIRST Bank As a bank that’s constantly at the
              forefront of introducing customer friendly features such as
              Monthly Interest Credits on Savings Account, ‘never expiring
              reward points’ on credit cards, lifetime free credit cards etc.,
              we are happy to be a part of this pilot initiative of RBI in
              bringing the digital rupee revolution in India.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Faq */}

      <div className="p-10">
        <h2 className="text-2xl font-bold tracking-tight text-dark4 sm:text-2xl">
          FREQUENTLY ASKED QUESTIONS
        </h2>
        <div className=" mt-4 space-y-4">
          <details
            className="group [&_summary::-webkit-details-marker]:hidden"
            open
          >
            <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
              <h2 className="font-medium">
                What is a central bank digital currency (CBDC)?
              </h2>

              <svg
                className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </summary>

            <p className="mt-4 px-4 leading-relaxed text-gray-700">
              Central Bank Digital Currency (CBDC) also known as “Digital Rupee”
              or “e₹” is a legal tender, similar to sovereign paper currency,
              and is issued in digital form by the Reserve Bank of India. e₹
              will offer features of physical cash like trust, safety and
              settlement finality with atomicity (i.e. immediate settlement of
              transactions) in digital mode. e₹ represents a direct claim on the
              central bank. It can be used to carry out transactions or store
              value digitally, similar to the manner in which currency notes can
              be used in physical form.
            </p>
          </details>

          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
              <h2 className="font-medium">
                How e₹ is different from UPI or other fund transfer mode
                (NEFT/RTGS/IMPS)?
              </h2>

              <svg
                className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </summary>

            <p className="mt-4 px-4 leading-relaxed text-gray-700">
              e₹ is a form of money, a digital representation of physical
              currency, whereas UPI or other fund transfer modes are forms of
              payment. Therefore, usage of e₹ is not limited to payments. e₹
              also serves the purpose of ‘unit of account’ and importantly,
              ‘store of value’ as it represents a claim on the Reserve Bank’s
              balance sheet. Moreover, e₹ will have additional attributes
              specific to currency which will be tested in future pilots.
            </p>
          </details>
          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
              <h2 className="font-medium">
                Is e₹ same as cryptocurrency such as bitcoin?
              </h2>

              <svg
                className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </summary>

            <p className="mt-4 px-4 leading-relaxed text-gray-700">
              No. e₹ is digital form of currency notes unlike other
              cryptocurrencies such as bitcoin. e₹ has intrinsic value and is
              regulated by the RBI. e₹ will always have same value as of
              physical bank currency notes which is a legal tender issued by the
              central bank unlike crypto assets such as bitcoin. Cryptos like
              bitcoin do not exhibit any features of a currency, do not have any
              intrinsic value, and are neither backed by assets nor issued by a
              central trusted authority.
            </p>
          </details>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
